<svelte:options strictprops={false} />

<script>
  import cloneDeep from "lodash/cloneDeep";
  import upperFirst from "lodash/upperFirst";
  import isEmpty from "lodash/isEmpty";

  import SortableThumbnailList from "src/lib/SortableThumbnailList.svelte";
  import ListThumbnail from "src/lib/ListThumbnail.svelte";
  import SidebarTitle from "src/lib/sidebar/SidebarTitle.svelte";
  import Sidebar from "src/lib/sidebar/Sidebar.svelte";
  import PrevNext from "src/lib/sidebar/PrevNext.svelte";
  import FilterInput from "./FilterInput.svelte";
  import ProductListManager from "src/lib/sidebar/ProductListManager.svelte";
  import ProductListSettings from "../sidebar/ProductListSettings.svelte";

  import { showRightPanel } from "src/stores/ui.js";
  import makeMultiProduct from "src/extensions/multi-product.js";

  export let disabled = false;
  export let products;
  export let selectedObj;
  export let recordName = "type";
  export let filter;
  export let shareable = false;

  let width;
  let height;

  $: filtered = filterProducts(filter, products);
  $: selectedList = products.filter((t) => $selectedObj[t.id]);
  $: selected = findSelected(selectedList);
  $: selectedIndex = products.indexOf(selectedList[0]);
  $: multiProduct = makeMultiProduct(selectedList, recordName);
  $: prev = selectedList.length === 1 && products[selectedIndex - 1];
  $: next = selectedList.length === 1 && products[selectedIndex + 1];

  function filterProducts(filter, products) {
    if (!filter) return products;
    const f = filter?.toLowerCase() || "";
    return products.filter((p) => {
      const name = p.name?.toLowerCase() || "";
      const category = p.category?.toLowerCase() || "";
      const classification = p.classification?.toLowerCase() || "";

      return name.includes(f) || category.includes(f) || classification.includes(f);
    });
  }

  function findSelected(s) {
    if (s.length !== 1) return null;

    const t = s[0];

    return t;
  }

  function select(e) {
    const { event, id } = e.detail;

    if (event.shiftKey && !isEmpty($selectedObj)) {
      const allIds = products.map((t) => t.id);
      const indices = products
        .map((t, i) => i)
        .filter((i) => $selectedObj[products[i].id] || products[i].id === id);

      const min = Math.min(...indices);
      const max = Math.max(...indices);

      const ids = allIds.slice(min, max + 1);
      selectedObj.selectOnly(...ids);
    } else if (event.metaKey) {
      if (!$selectedObj[id]) {
        selectedObj.select(id);
      } else {
        selectedObj.deselect(id);
      }
    } else {
      selectedObj.selectOnly(id);
    }
  }

  function selectNone() {
    $selectedObj = {};
  }

  function gotoPrev() {
    if (prev) selectedObj.selectOnly(prev.id);
  }

  function gotoNext() {
    if (next) selectedObj.selectOnly(next.id);
  }
</script>

<div class="bg-gray-100 h-full flex" bind:offsetWidth={width} bind:offsetHeight={height}>
  <SortableThumbnailList
    list={filtered}
    {disabled}
    selected={$selectedObj}
    let:record
    reorderable={!filter}
    filterable
    {shareable}
    bind:filter
    on:select={select}
    on:select-none={selectNone}
    on:add
    on:reorder
    on:share-selected
    on:clone-selected
    on:remove-selected>
    <ListThumbnail {record} wrapText>
      <div class="w-full h-full relative">
        <slot name="thumbnail" product={record} />
      </div>
    </ListThumbnail>
  </SortableThumbnailList>

  {#if $showRightPanel && width >= 640}
    <Sidebar>
      <svelte:fragment slot="header">
        {#if selectedList.length === 1 && selected}
          <PrevNext
            {prev}
            {next}
            on:gotoprev={gotoPrev}
            on:gotonext={gotoNext}
            sticky
            title={selected.name} />
        {/if}
      </svelte:fragment>
      <svelte:fragment slot="content">
        {#if selectedList.length === 0}
          <!-- <div class="px-6 text-xs italic">No {recordName}s selected.</div> -->
          <ProductListManager />
        {:else if selectedList.length === 1}
          {#key selected.id}
            <slot name="sidebar" {selected} />
            <ProductListSettings {selected} />
          {/key}
        {:else}
          {#key multiProduct.id}
            <SidebarTitle title="Multiple {upperFirst(recordName)}s Selected" />
            <slot name="multi-sidebar" selected={multiProduct} />
            <ProductListSettings selected={multiProduct} />
          {/key}
        {/if}
      </svelte:fragment>
    </Sidebar>
  {/if}
</div>
