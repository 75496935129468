<script>
  import { createEventDispatcher } from "svelte";
  import ContenteditableInput from "./ContenteditableInput.svelte";
  import ImageInput from "./ImageInput.svelte";
  import imageUrl from "src/extensions/image-url.js";

  import { api } from "src/api";

  export let category;
  export let disabled;
  export let org;

  const dispatch = createEventDispatcher();

  let categoryModal;

  $: multiple = Array.isArray(category.id);

  function update(prop, value) {
    dispatch("update", { id: category.id, prop, value });
  }

  async function uploadImage(e) {
    if (e.detail.type && e.detail.data) {
      const uuid = crypto.randomUUID();
      const filename = `${uuid}/${e.detail.filename}`;
      const { data: result, error } = await api.storage.from("images").upload(filename, e.detail.data, {
        contentType: e.detail.contentType,
      });

      if (!error) {
        const image = {
          id: uuid,
          bucket: "images",
          object_id: result.path,
          content_type: e.detail.contentType,
          filename: e.detail.filename,
        };
        dispatch("update", { id: category.id, prop: "image", value: image });
      }
    }
  }
</script>

<div class="text-xs px-4">
  {#if !multiple}
    <ImageInput
      label="Image"
      labelWidth="6rem"
      value={imageUrl(category.image)}
      accept="image/png, image/jpeg"
      uploadType="arraybuffer"
      {disabled}
      on:upload={uploadImage}
      on:delete={() => update("image", null)} />
  {/if}
  <ContenteditableInput
    label="Name"
    {disabled}
    labelWidth="6rem"
    value={category.name}
    on:input={(evt) => update("name", evt.detail.value)} />
</div>
