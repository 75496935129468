<script>
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import ColorInput from "src/lib/sidebar/ColorInput.svelte";
  import BooleanInput from "src/lib/sidebar/BooleanInput.svelte";
  import GuardedTextInput from "src/lib/sidebar/GuardedTextInput.svelte";
  import { Modal } from "svelte-utilities";
  import { profile as userProfile, user } from "src/stores/auth.js";
  import { api, sendMagicLink, setUserPassword } from "src/api";

  export let profile;
  export let isUser = false;

  $: canAdmin = $userProfile && ["org_admin", "developer"].includes($userProfile.user_role);

  let confirmResendLinkModal;
  let confirmResetPasswordModal;

  function updateProp(prop, value) {
    if (!profile?.id) return;

    api
      .from("profiles")
      .update({ [prop]: value })
      .eq("id", profile.id)
      .then(({ data, error }) => {
        if (error) console.log(error);
      });

    if ($userProfile.id === profile.id) {
      $userProfile[prop] = value;
    }
  }

  function confirmResendLink() {
    confirmResendLinkModal.open();
  }

  function confirmResetPassword() {
    confirmResetPasswordModal.open();
  }

  function resendLink() {
    sendMagicLink(profile.id);
  }

  function sendPasswordReset() {
    user.resetPassword($user.email, "passreset");
  }

  function toggleDisabled(value) {
    if (value) {
      updateProp("disabled_at", new Date());
    } else {
      updateProp("disabled_at", null);
    }
  }

  async function updatePassword(password) {
    const { error } = await setUserPassword({ id: profile.id, password });
    if (error) {
      console.log("Set password failed:", error);
    }
  }
</script>

<div class="px-4 pb-4 border-b border-gray-300">
  <h2>Profile</h2>
</div>

{#if $userProfile}
  <div class="py-4 sm:px-4 text-sm flex">
    <div class="w-full sm:w-96 space-y-4">
      <TextInput
        labelWidth="8rem"
        border
        label="Name"
        value={profile.username}
        on:input={(evt) => updateProp("username", evt.detail.value)}
        disabled={!canAdmin && !isUser} />
      <TextInput labelWidth="8rem" border label="Email" value={profile.email} disabled />
      {#if profile.organization}
        <TextInput labelWidth="8rem" border label="Organization" value={profile.organization.name} disabled />
      {/if}
      <SelectInput
        labelWidth="8rem"
        border
        label="User Role"
        value={profile.user_role}
        on:input={(evt) => updateProp("user_role", evt.detail.value)}
        disabled={!canAdmin}
        options={[
          { label: "User", value: "user" },
          { label: "Product User", value: "product_user" },
          { label: "Admin", value: "org_admin" },
          ...(profile.user_role === "developer" ? [{ label: "Developer", value: "developer" }] : []),
        ]} />
      {#if profile.organization?.org_type === "supplier"}
        <BooleanInput
          labelWidth="8rem"
          border
          label="Public Email"
          value={profile.is_public}
          on:input={(e) => updateProp("is_public", e.detail.value)} />
      {/if}
      <ColorInput
        labelWidth="8rem"
        border
        label="Profile Color"
        value={profile.user_color}
        disabled={!canAdmin && !isUser}
        on:input={(e) => updateProp("user_color", e.detail.value)} />
      {#if $userProfile.user_role === "developer"}
        <GuardedTextInput
          labelWidth="8rem"
          warningText="Set new user password:"
          border
          password
          label="User Password"
          value={""}
          on:input={(e) => updatePassword(e.detail.value)} />
      {/if}
      {#if canAdmin && !isUser}
        <BooleanInput
          labelWidth="8rem"
          border
          label="Deactivated"
          disabled={$userProfile.user_role !== "developer"}
          value={!!profile.disabled_at}
          on:input={(e) => toggleDisabled(e.detail.value)} />
        <button class="my-4 px-4 btn btn-primary w-full" on:click={confirmResendLink}>
          Re-send Magic Link
        </button>
      {/if}
      {#if isUser}
        <button class="my-4 px-4 btn btn-primary w-full" on:click={confirmResetPassword}>
          Send Password Reset Link
        </button>
      {/if}
    </div>
  </div>
{/if}

<Modal
  bind:this={confirmResendLinkModal}
  on:confirm={resendLink}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Send", type: "confirm", style: "primary" },
  ]}
  closeOnOutclick>
  <div slot="title">Resend Link</div>
  <div slot="content">
    Are you sure you want to resend a magic link to {profile.username}?
  </div>
</Modal>

<Modal
  bind:this={confirmResetPasswordModal}
  on:confirm={sendPasswordReset}
  buttons={[
    { label: "Cancel", type: "cancel" },
    { label: "Send", type: "confirm", style: "primary" },
  ]}
  closeOnOutclick>
  <div slot="title">Reset Password</div>
  <div slot="content">
    Are you sure you want to reset your password? You will receive an email with your password reset link.
  </div>
</Modal>
