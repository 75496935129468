export default function sortByCategory(types, categories) {
  const grouped = types.reduce(
    (g, type) => {
      const category = type.category_id ?? type.category;
      if (category) {
        if (g[category]) {
          g[category].push(type);
        } else {
          g[category] = [type];
        }
      } else {
        g._uncategorized.push(type);
      }
      return g;
    },
    { _uncategorized: [] },
  );

  const u = grouped._uncategorized;
  delete grouped._uncategorized;

  const cats = categories.order.filter((id) => grouped[id]);
  const others = Object.keys(grouped).filter((id) => !categories[id]);

  const result = [];

  cats.forEach((cat) => {
    result.push(...grouped[cat]);
  });
  others.forEach((cat) => {
    result.push(...grouped[cat]);
  });

  result.push(...u);

  return result;
}
