<script>
  import { onMount } from "svelte";
  import { Modal } from "svelte-utilities";
  import eb from "src/extensions/event-bus.js";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import BooleanInput from "src/lib/sidebar/BooleanInput.svelte";

  export let group;

  let modal;

  function openModal() {
    modal.open();
  }

  function updateSetting(setting, value) {
    group.updateProp(`data.settings.${setting}`, value);
  }

  onMount(() => {
    eb.on("open-home-settings-modal", openModal);

    return () => {
      eb.unsubscribe("open-home-settings-modal", openModal);
    };
  });
</script>

<Modal bind:this={modal} closeable fullframe width="36rem" height="26rem">
  <div slot="title">Settings</div>
  <div slot="content" class="text-xs h-full overflow-auto p-4 space-y-2">
    <div class="flex items-center justify-between">
      <div>Default Type View</div>
      <div class="flex-none w-56">
        <SelectInput
          options={[
            { label: "Show Type Details", value: -1 },
            { label: "Show Type Details and Items", value: 1 },
            { label: "Show Type Name Only", value: 0 },
          ]}
          value={$group.data.settings?.summary_default_type_view}
          label={null}
          on:input={(e) => updateSetting("summary_default_type_view", e.detail.value)} />
      </div>
    </div>
    <div class="flex items-center justify-between">
      <div>Show Untyped Items</div>
      <div class="flex-none w-56">
        <BooleanInput
          value={$group.data.settings?.summary_show_untyped_items}
          label={null}
          on:input={(e) => updateSetting("summary_show_untyped_items", e.detail.value)} />
      </div>
    </div>
  </div>
</Modal>
