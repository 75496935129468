<script>
  import { onMount, onDestroy, createEventDispatcher, getContext, setContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import { downloadString } from "downloaders";
  import isEmpty from "lodash/isEmpty";

  import DownloadIcon from "src/assets/icons/download.svg";
  import BusinessIcon from "src/assets/icons/business.svg";
  import TagIcon from "src/assets/icons/tag.svg";
  import NotesIcon from "src/assets/icons/notes.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import NumberIcon from "src/assets/icons/number.svg";
  import GearIcon from "src/assets/icons/gear.svg";
  import Quote from "./Quote.svelte";
  import QuoteModal from "./QuoteModal.svelte";
  import DocumentsInput from "src/lib/home/DocumentsInput.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";

  import GroupSummary from "./GroupSummary.svelte";
  import GroupAttachments from "./GroupAttachments.svelte";
  import SummaryTable from "./SummaryTable.svelte";
  import ProductSummaryTable from "./ProductSummaryTable.svelte";
  import HamburgerMenu from "src/lib/HamburgerMenu.svelte";
  import HamburgerMenuItem from "src/lib/HamburgerMenuItem.svelte";
  import DownloadModal from "./DownloadModal.svelte";
  import HomeSettingsModal from "./HomeSettingsModal.svelte";
  import { Modal } from "svelte-utilities";
  import Notifications from "./Notifications.svelte";

  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { quotes, fetchQuote, fullQuoteStr } from "src/stores/quotes.js";
  import { fetchGroup, createGroup } from "src/stores/group.js";
  import eb from "src/extensions/event-bus.js";
  import copyToClipboard from "src/extensions/copy-to-clipboard.js";
  import formatDateTime from "@local/extensions/formatters/format-date-time.js";
  import { typeColor, typeColorTrue } from "@local/extensions/drawing/type-color.js";
  import { api } from "src/api";
  import { reviseQuote, submitQuoteOnly, cancelQuote } from "src/api/requests.js";
  import { profile } from "src/stores/auth.js";
  import { currentSupplierid } from "src/stores/ui.js";
  import { exportJson } from "src/extensions/export-job/json.js";
  import { bucketArrayMultiple } from "overline";
  import getAssignToOptions from "src/extensions/assign-to-options.js";
  import { orgLibrary } from "src/extensions/makeup-library.js";

  export let quoteid;

  const dispatch = createEventDispatcher();

  let quote;
  let cancelQuoteModal;
  let group;

  const org = getContext("org");
  const productLists = getContext("productLists");
  const supplier = getContext("supplier");
  const typeColors = writable({});
  const typeColorsTrue = writable({});

  const makeupLibrary = derived(supplier, ($supplier) => orgLibrary($supplier, $group?.project_type));

  setContext("typeColors", typeColors);
  setContext("typeColorsTrue", typeColorsTrue);
  setContext("makeupLibrary", makeupLibrary);

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  $: canceled = quote?.quote_request.status === "canceled";
  $: archived = quote?.archived_at;
  $: address = quote?.job.location?.split("\n") || [];
  $: addressFirstLine = address[0];
  $: assignToOptions = getAssignToOptions($profile, $org);
  $: allAttachments = $group && bucketArrayMultiple($group.attachments, "item_id", "type_id");

  $: items = transform($group?.items, orderedList).filter((i) => !i.is_collection);
  $: types = transform($group?.types, orderedList);
  $: productList = $productLists?.find((pl) => pl.id === quote?.job.product_list_id);
  $: $typeColors = types.reduce((a, t) => {
    a[t.id] = typeColor(t);
    return a;
  }, {});
  $: $typeColorsTrue = types.reduce((a, t) => {
    a[t.id] = typeColorTrue(t);
    return a;
  }, {});

  async function updatePendingQuote(update) {
    const data = quote.data;
    data.pending_quote = {
      ...data.pending_quote,
      ...update,
    };

    await api.from("quotes").update({ data }).eq("id", quoteid).select("*").single();

    quote = await fetchQuote(quoteid);
    quotes.fetchOne(quoteid);
  }

  async function updateQuote(update) {
    await api.from("quotes").update(update).eq("id", quoteid).select("*").single();

    quote = await fetchQuote(quoteid);
    quotes.fetchOne(quoteid);
  }

  async function addAttachment(e) {
    const { attachment } = e.detail;
    const path = `${quote.id}/${attachment.id}.${attachment.extension}`;

    const { error } = await api.storage
      .from("quotes")
      .upload(path, attachment.data, { contentType: attachment.content_type });

    if (error) return;
    const existing = quote.data.pending_quote?.attachments || [];

    const attachments = [
      ...existing,
      {
        id: attachment.id,
        quote_id: quote.id,
        extension: attachment.extension,
        name: attachment.name,
        path,
        uploaded_at: attachment.uploaded_at,
      },
    ];

    await updatePendingQuote({ attachments });
  }

  async function removeAttachment(e) {
    const { attachment } = e.detail;
    const attachments = quote.data.pending_quote.attachments.filter((a) => a.id !== attachment.id);
    await updatePendingQuote({ attachments });
  }

  async function sendQuote() {
    if (quote.sent_at) {
      await reviseQuote({ ...quote.data.pending_quote, quote_id: quoteid, sender_id: $profile.id });
      quote = await fetchQuote(quoteid);
      quotes.fetchOne(quoteid);
    } else {
      await submitQuoteOnly({ ...quote.data.pending_quote, quote_id: quoteid, sender_id: $profile.id });
      quote = await fetchQuote(quoteid);
      quotes.fetchOne(quoteid);
    }
  }

  async function cancelQ() {
    await cancelQuote({ quote_id: quoteid });
    quote = await fetchQuote(quoteid);
    quotes.fetchOne(quoteid);
  }

  async function copyLink() {
    copyToClipboard(`${window.location.origin}/quotes/${quoteid}`);
  }

  async function archiveQuote() {
    await quotes.archive(quoteid);
    quote = await fetchQuote(quoteid);
    dispatch("archive", { id: quoteid });
  }

  async function unarchiveQuote() {
    await quotes.unarchive(quoteid);
    quote = await fetchQuote(quoteid);
    dispatch("unarchive", { id: quoteid });
  }

  async function downloadJSON() {
    const json = exportJson($group, quote.job);
    downloadString(json, `${quote.job.name}.json`);
  }

  async function update() {
    quote = await fetchQuote(quoteid);
    quotes.fetchOne(quoteid);
  }

  async function openQuoteModal() {
    if (isEmpty(quote.data.pending_quote)) {
      await updatePendingQuote({
        price: quote.price,
        days_valid: quote.days_valid,
        notes: quote.notes,
        attachments: quote.data.attachments || [],
      });
    }

    eb.dispatch("open-quote-modal");
  }

  function openJobSettings() {
    eb.dispatch("open-home-settings-modal");
  }

  onMount(async () => {
    if (!quoteid) return;

    eb.on("prep-open-quote", openQuoteModal);

    try {
      let quoteData = await fetchQuote(quoteid);

      if (quoteData.status !== "submitted" && !quoteData.data.pending_quote) {
        const data = {
          ...quoteData.data,
          pending_quote: {
            days_valid: null,
            price: null,
            notes: "",
            attachments: [],
          },
        };

        const { data: qd, error } = await api
          .from("quotes")
          .update({ data })
          .eq("id", quoteid)
          .select(fullQuoteStr)
          .single();

        if (!error) {
          quoteData = qd;
        }
      }

      quote = quoteData;
      if (quote?.quote_request?.group_id) {
        const gd = await fetchGroup(quote.quote_request.group_id);
        const g = createGroup(gd);
        group = g;
      }
      $currentSupplierid = quote.organization_id;
    } catch (error) {
      console.log(error);
    }
  });

  onDestroy(() => {
    eb.unsubscribe("prep-open-quote", openQuoteModal);
  });
</script>

{#if quote}
  <div class="w-full space-y-4">
    <div class="flex justify-between items-start border-b pb-4 mx-4">
      <div class="grow flex items-start gap-2">
        <div>
          <div class="font-bold w-48 truncate">
            {addressFirstLine || ""}&nbsp;
          </div>
          <div class="text-xs">
            Received {formatDateTime(quote.quote_request.sent_at)}
          </div>
        </div>
        <GroupSummary {group} {items} {types} />
      </div>

      <div class="flex gap-2">
        {#if $profile?.user_role !== "product_user"}
          <button class="btn btn-icon" on:click={() => eb.dispatch("open-download")}>
            <DownloadIcon />
          </button>
        {/if}
        <div class="btn-bare">
          <HamburgerMenu>
            <HamburgerMenuItem label="Copy Link" icon="link" on:click={copyLink} />
            <!-- <HamburgerMenuItem label="Clone to New Job" icon="duplicate" on:click={cloneToNewJob} /> -->
            {#if !canceled && quote.status !== "canceled" && quote.sent_at}
              <HamburgerMenuItem label="Cancel Quote" icon="x" on:click={() => cancelQuoteModal.open()} />
            {/if}
            {#if archived}
              <HamburgerMenuItem label="Unarchive" icon="unarchive" on:click={unarchiveQuote} />
            {:else}
              <HamburgerMenuItem label="Archive" icon="archive" on:click={archiveQuote} />
            {/if}
            {#if $profile?.user_role === "developer"}
              <HamburgerMenuItem label="Download JSON" icon="download" on:click={downloadJSON} />
            {/if}
            {#if $group?.project_type !== "product"}
              <HamburgerMenuItem label="Settings" on:click={openJobSettings}>
                <GearIcon />
                <div class="whitespace-nowrap">Settings</div>
              </HamburgerMenuItem>
            {/if}
          </HamburgerMenu>
        </div>
      </div>
    </div>
    {#if quote.notifications.length}
      <div class="px-4">
        <Notifications notifications={quote.notifications} on:clear={update} />
      </div>
    {:else if canceled}
      <div class="italic text-xs">This RFQ has been canceled.</div>
    {/if}

    <div class="content-columns px-4">
      <div class="left-column space-y-2">
        {#if quote.quote_request.organization_id !== $org.id}
          <div>
            <div class="flex gap-2 items-start">
              <div class="py-1">
                <BusinessIcon />
              </div>
              <div>
                <div class="text-sm font-bold">
                  {quote.quote_request.organization.name}
                </div>

                <div class="text-gray-400">
                  {quote.quote_request.organization.address || ""}
                </div>
              </div>
            </div>
          </div>
        {/if}
        <div class="space-y-3">
          {#if quote.quote_request.organization_id !== $org.id}
            <div class="flex gap-2">
              <div>
                <ProfileIcon />
              </div>
              <div>
                {quote.quote_request.sender?.username || ""}
              </div>
            </div>
          {/if}
          {#if quote.job.customer_record}
            <div class="flex gap-2">
              <div>
                <ProfileIcon />
              </div>
              <div>
                {quote.job.customer_record.name}
                <span class="text-gray-500 italic">({quote.job.customer_record.email})</span>
              </div>
            </div>
          {:else if quote.job.customer_text}
            <div class="flex gap-2">
              <div>
                <ProfileIcon />
              </div>
              <div>
                {quote.job.customer_text}
              </div>
            </div>
          {/if}
          {#if quote.job.location}
            <div class="flex gap-2">
              <div>
                <LocationIcon />
              </div>
              <div>
                {quote.job.location}
              </div>
            </div>
          {/if}
          <div class="flex gap-2">
            <div>
              <TagIcon />
            </div>
            <div>
              For
              {quote.quote_request.request_for}
              {#if quote.quote_request.request_for === "other"}
                (see notes)
              {/if}
            </div>
          </div>
          {#if $group?.project_type === "product"}
            <div class="flex gap-2">
              <div>
                <NumberIcon />
              </div>
              <div>
                {$group.seller_reference || ""}
              </div>
            </div>
            <div class="flex gap-2">
              <div class="w-4 text-center">$</div>
              <div>
                {productList?.name || ""}
              </div>
            </div>
          {:else}
            <div class="flex gap-2">
              <div>
                <NumberIcon />
              </div>
              <div>
                {quote.job.buyer_reference || ""}
              </div>
            </div>
          {/if}
          {#if quote.job.notes}
            <div class="flex gap-2">
              <div>
                <NotesIcon />
              </div>
              <div class="whitespace-pre-line">
                {quote.job.notes}
              </div>
            </div>
          {/if}
        </div>
        <DocumentsInput {group} disabled sent />
        <GroupAttachments {group} disabled attachments={allAttachments?.none} />
      </div>

      <div class="right-column text-xs space-y-2">
        <div class="flex flex-col items-start sm:items-end">
          <div class="w-full">
            <SelectInput
              labelWidth="1rem"
              options={assignToOptions}
              placeholder="Unassigned"
              value={quote.assigned_to_id}
              outerBorder={false}
              showCaret
              on:input={(e) => updateQuote({ assigned_to_id: e.detail.value })}>
              <div class="text-black" slot="label"><ProfileIcon /></div>
              <div slot="selected" let:option class="flex items-center gap-1">
                {#if option.initials}
                  <div
                    class="profile-circle relative"
                    style="background-color: {option.color || '#000'}; border-color: {option.color ||
                      '#000'}">
                    {option.initials}
                  </div>
                {/if}
                <div class:text-gray-400={!option.value}>
                  {option.label}
                </div>
              </div>
              <div slot="option" let:option class="flex items-center gap-1 overflow-hidden">
                {#if option.initials}
                  <div
                    class="profile-circle relative"
                    style="background-color: {option.color || '#000'}; border-color: {option.color ||
                      '#000'}">
                    {option.initials}
                  </div>
                {:else}
                  <div class="flex-none h-4 w-4" />
                {/if}
                <div class:text-gray-400={!option.value} class="truncate">
                  {option.label}
                </div>
              </div>
            </SelectInput>
          </div>
        </div>
        {#if quote.sent_at}
          <div class="text-xs space-y-2">
            <!-- <div class="font-bold text-sm">Response</div> -->
            <Quote rfq={quote.quote_request} {quote} quoter on:revise-quote={openQuoteModal} />
          </div>
        {/if}
      </div>

      <div class="center-column space-y-2">
        {#if $group?.project_type === "product"}
          <ProductSummaryTable
            recordId={quote.id}
            recordType="quote"
            sent
            {group}
            {types}
            {items}
            {org}
            {quote}
            job={quote?.job} />
        {:else if $group}
          <SummaryTable sent {group} {types} {items} {org} attachments={allAttachments} />
        {/if}
      </div>
    </div>
  </div>

  <QuoteModal
    {quote}
    {group}
    {items}
    {types}
    on:send={sendQuote}
    on:update-pending={(e) => updatePendingQuote(e.detail)}
    on:add-attachment={addAttachment}
    on:remove-attachment={removeAttachment} />

  <Modal
    bind:this={cancelQuoteModal}
    closeable
    on:confirm={cancelQ}
    buttons={[
      { label: "Cancel", type: "cancel" },
      { label: "Cancel Quote", type: "confirm", style: "danger" },
    ]}>
    <div slot="title">Cancel Quote</div>
    <div slot="content" class="space-y-4">
      <div>Are you sure you want to cancel this quote?</div>
      <div class="font-bold space-y-2">
        <div>{quote.job.name}</div>
      </div>
    </div>
  </Modal>

  <DownloadModal title="Download Contents" {group} {types} name={quote.job.name} rfq={quote?.quote_request} />
  <HomeSettingsModal {group} />
{/if}

<style lang="scss">
  .content-columns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    overflow: hidden;

    .left-column,
    .right-column {
      @apply text-xs;
      width: 100%;
    }

    @media (min-width: 768px) {
      .left-column,
      .right-column {
        width: calc(50% - 0.25rem);
      }
    }

    .center-column {
      @apply grow overflow-x-hidden;
    }
  }

  @media (min-width: 1636px) {
    .content-columns {
      flex-wrap: nowrap;

      .left-column,
      .right-column {
        width: 20rem;
      }

      .right-column {
        order: 1;
      }
    }
  }
</style>
