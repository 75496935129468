<script>
  import { navigate } from "svelte-routing";
  import { onMount, getContext, setContext } from "svelte";
  import { writable, derived } from "svelte/store";
  import { downloadString } from "downloaders";
  import uniqBy from "lodash/uniqBy";
  import debounce from "lodash/debounce";

  import BusinessIcon from "src/assets/icons/business.svg";
  import LocationIcon from "src/assets/icons/location.svg";
  import NotesIcon from "src/assets/icons/notes.svg";
  import NumberIcon from "src/assets/icons/number.svg";
  import DownloadIcon from "src/assets/icons/download.svg";
  import GearIcon from "src/assets/icons/gear.svg";
  import HamburgerMenu from "../HamburgerMenu.svelte";
  import HamburgerMenuItem from "../HamburgerMenuItem.svelte";
  import ProfileIcon from "src/assets/icons/profile.svg";
  import DownloadModal from "./DownloadModal.svelte";

  import SearchSelect from "src/lib/sidebar/SearchSelect.svelte";
  import SelectInput from "src/lib/sidebar/SelectInput.svelte";
  import ContactInput from "src/lib/sidebar/ContactInput.svelte";
  import TextInput from "src/lib/sidebar/TextInput.svelte";
  import DocumentsInput from "src/lib/home/DocumentsInput.svelte";
  import GroupAttachments from "./GroupAttachments.svelte";
  import ContenteditableInput from "src/lib/sidebar/ContenteditableInput.svelte";
  import MapboxInput from "src/lib/sidebar/MapboxInput.svelte";
  import SummaryTable from "./SummaryTable.svelte";
  import ProductSummaryTable from "./ProductSummaryTable.svelte";
  import GroupSummary from "./GroupSummary.svelte";
  import ShareDraftModal from "./ShareDraftModal.svelte";
  import ProductSendModal from "./ProductSendModal.svelte";
  import HomeSettingsModal from "./HomeSettingsModal.svelte";

  import { orderedList } from "@local/extensions/collections/sortable-list.js";
  import { profile } from "src/stores/auth.js";
  import { jobs, fetchJob, selectedJobIds } from "src/stores/jobs.js";
  import { quotes, selectedQuoteIds } from "src/stores/quotes.js";
  import { fetchGroup, createGroup } from "src/stores/group.js";

  import { typeColor, typeColorTrue } from "@local/extensions/drawing/type-color.js";
  import { bucketArrayMultiple, objectify } from "overline";
  import sanitizePgString from "src/extensions/sanitize-postgrest-string.js";
  import getAssignToOptions from "src/extensions/assign-to-options.js";
  import { exportJson } from "src/extensions/export-job/json.js";
  import { currentSupplierid } from "src/stores/ui.js";
  import eb from "src/extensions/event-bus.js";
  import { api } from "src/api";
  import { selfSendRequest } from "src/api/requests.js";
  import { orgLibrary } from "src/extensions/makeup-library.js";
  import { findChildProductList } from "@local/lamina-core/reporters";

  export let jobid;

  const org = getContext("org");
  const productLists = getContext("productLists");
  const supplier = getContext("supplier");
  const typeColors = writable({});
  const typeColorsTrue = writable({});

  let job;
  let group;
  let customerSearchString = "";
  let selectedCustomer = null;
  let customers = [];

  const makeupLibrary = derived(supplier, ($supplier) => orgLibrary($supplier, $group?.project_type));

  setContext("typeColors", typeColors);
  setContext("typeColorsTrue", typeColorsTrue);
  setContext("makeupLibrary", makeupLibrary);

  function transform(coll, xf, defaultValue = []) {
    return coll ? xf(coll) : defaultValue;
  }

  $: archived = job?.archived_at;
  $: address = job?.location?.split("\n") || [];
  $: addressFirstLine = address[0];
  $: addressRest = address.slice(1);
  $: assignToOptions = getAssignToOptions($profile, $org);
  $: allAttachments = $group && bucketArrayMultiple($group.attachments, "item_id", "type_id");

  $: items = transform($group?.items, orderedList).filter((i) => !i.is_collection);
  $: types = transform($group?.types, orderedList);
  $: $typeColors = types.reduce((a, t) => {
    a[t.id] = typeColor(t);
    return a;
  }, {});
  $: $typeColorsTrue = types.reduce((a, t) => {
    a[t.id] = typeColorTrue(t);
    return a;
  }, {});

  $: customerResults = customers.map((customer) => ({
    id: customer.id,
    name: customer.name,
    // cta: customer.primary_contact_email,
  }));
  $: areaNumeric = sumArea(items);

  // NOTE: this depends on the product lists being ordered so that the child lists
  // follow the parent list in the array
  $: childPls = $productLists.reduce((children, pl) => {
    if (!pl.parent_id) {
      children[pl.id] = [];
    } else {
      children[pl.parent_id].push(pl);
    }

    return children;
  }, {});
  $: plOptions = $productLists.filter((pl) => !pl.parent_id).map((pl) => plOption(pl, childPls, areaNumeric));

  async function updateJob(update) {
    await api.from("jobs").update(update).eq("id", jobid).select("*").single();

    job = await fetchJob(jobid);
    jobs.fetchOne(jobid);
  }

  function plOption(pl, childPls, area) {
    let label = pl.name;
    const children = childPls[pl.id];

    if (children?.length) {
      const selected = findChildProductList(children, area);
      if (selected) {
        label = `${pl.name}: ${selected.name}`;
      }
    }

    return { label, value: pl.id };
  }

  function sumArea(items) {
    return items.reduce((acc, item) => {
      return acc + (item.cache?.area || 0);
    }, 0);
  }

  async function updateGroup(prop, value) {
    group.updateProp(prop, value);
  }

  async function downloadJSON() {
    const json = exportJson($group, job);
    downloadString(json, `${job.name}.json`);
  }

  async function archiveJob() {
    await jobs.archive(jobid);
  }

  async function unarchiveJob() {
    await jobs.unarchive(jobid);
    job = await fetchJob(jobid);
    jobs.fetchOne(jobid);
  }

  async function fetchCustomers() {
    const s = sanitizePgString(customerSearchString);

    const c = await api
      .from("business_relation_list_view")
      .select("*,buyer:buyer_id(*)")
      .eq("seller_id", $org.id)
      .or(`recipient_name.ilike.%${s}%,recipient_email.ilike.%${s}%`)
      .range(0, 8);

    if (!c.error) {
      customers = uniqBy(c.data, "buyer_id").map((c) => c.buyer);
    }
  }

  function searchCustomers() {
    debounce(fetchCustomers, 500)();
  }

  function selectCustomer(e) {
    updateJob({ prepared_for_org_id: e.detail.result.id });
    selectedCustomer = e.detail.result;
  }

  function deselectCustomer() {
    updateJob({ prepared_for_org_id: null });
    selectedCustomer = null;
    customerSearchString = "";
    fetchCustomers();
  }

  async function selfSend(e) {
    jobs.spin(jobid);
    const quote = await selfSendRequest({ job_id: jobid, sender_id: $profile.id });
    if (quote.error) {
      console.log(quote.error);
      return;
    }
    await quotes.fetchOne(quote.data.id);
    $selectedJobIds = [];
    $selectedQuoteIds = [quote.data.id];
    jobs.unloadOne(jobid);
  }

  function openJobSettings() {
    eb.dispatch("open-home-settings-modal");
  }

  onMount(async () => {
    if (!jobid) return;
    fetchCustomers();

    try {
      job = await fetchJob(jobid);
      const gd = await fetchGroup(job?.current_group_id);
      group = createGroup(gd);
      if (job?.prepared_for_org_id) {
        const customer = await api
          .from("organizations")
          .select("*")
          .eq("id", job.prepared_for_org_id)
          .single();

        if (customer.data) {
          selectedCustomer = {
            id: customer.data.id,
            name: customer.data.name,
          };
        }
      }
      $currentSupplierid = $org.id;
    } catch (error) {
      console.error(error);
    }
  });
</script>

{#if job}
  <div class="w-full space-y-4">
    <div class="flex justify-between items-start border-b pb-4 mx-4">
      <div class="grow flex items-start gap-2">
        <div>
          <div class="font-bold w-48 truncate">
            {addressFirstLine || ""}&nbsp;
          </div>
          <div class="text-xs">
            {#each addressRest as line}
              <div>{line}</div>
            {/each}
          </div>
        </div>
        <GroupSummary {group} {items} {types} />
      </div>

      <div class="flex gap-2">
        {#if $profile?.user_role !== "product_user"}
          <button class="btn btn-icon" on:click={() => eb.dispatch("open-download")}>
            <DownloadIcon />
          </button>
        {/if}
        <div class="btn-bare">
          <HamburgerMenu>
            {#if archived}
              <HamburgerMenuItem label="Unarchive" icon="unarchive" on:click={unarchiveJob} />
            {:else}
              <HamburgerMenuItem label="Archive" icon="archive" on:click={archiveJob} />
            {/if}
            {#if $profile?.user_role === "developer"}
              <HamburgerMenuItem label="Download JSON" icon="download" on:click={downloadJSON} />
            {/if}
            <HamburgerMenuItem label="Settings" on:click={openJobSettings}>
              <GearIcon />
              <div class="whitespace-nowrap">Settings</div>
            </HamburgerMenuItem>
          </HamburgerMenu>
        </div>
      </div>
    </div>

    <div class="content-columns px-4">
      <div class="left-column space-y-2">
        {#if $profile?.user_role !== "product_user" && customers.length}
          <SearchSelect
            placeholder="Customer Org"
            label="Customer"
            labelWidth="1rem"
            outerBorder={false}
            searchIcon={false}
            bind:value={customerSearchString}
            selection={selectedCustomer}
            results={customerResults}
            on:select={selectCustomer}
            on:deselect={deselectCustomer}
            on:search={searchCustomers}>
            <div class="text-black" slot="label">
              <BusinessIcon />
            </div>
          </SearchSelect>
        {/if}
        {#if $group?.project_type === "product"}
          {#if $org.crm_integration_id}
            <ContactInput
              label={null}
              labelWidth="1rem"
              value={job.customer_record}
              on:input={(e) => updateJob({ customer_record: e.detail.value })}
              outerBorder={false}
              placeholder="Customer"
              orgid={$org.id}>
              <div class="text-black" slot="label">
                <ProfileIcon />
              </div>
            </ContactInput>
          {:else}
            <TextInput
              labelWidth="1rem"
              value={job.customer_text}
              on:input={(e) => updateJob({ customer_text: e.detail.value })}
              outerBorder={false}
              placeholder="Customer">
              <div class="text-black" slot="label">
                <ProfileIcon />
              </div>
            </TextInput>
          {/if}
        {/if}
        <MapboxInput
          labelWidth="1rem"
          value={job.location}
          coordinates={job.location_coordinates}
          outerBorder={false}
          placeholder="Location"
          on:input={(e) =>
            updateJob({
              location: e.detail.value,
              location_coordinates: e.detail.coordinates,
              location_postal_code: e.detail.postal_code,
              location_country_code: e.detail.country_code,
            })}>
          <div class="text-black" slot="label">
            <LocationIcon />
          </div>
        </MapboxInput>
        {#if $group?.project_type === "product"}
          <TextInput
            labelWidth="1rem"
            value={$group.seller_reference}
            outerBorder={false}
            disabled
            placeholder="PO Number">
            <div class="text-black" slot="label">
              <NumberIcon />
            </div>
          </TextInput>
          <SelectInput
            labelWidth="1rem"
            outerBorder={false}
            value={job.product_list_id}
            options={plOptions}
            on:input={(e) => updateJob({ product_list_id: e.detail.value })}>
            <div class="text-black text-center" slot="label">$</div>
          </SelectInput>
        {/if}
        <ContenteditableInput
          placeholder="Notes"
          labelWidth="1rem"
          value={job.notes}
          outerBorder={false}
          on:input={(e) => updateJob({ notes: e.detail.value })}>
          <div class="text-black" slot="label">
            <NotesIcon />
          </div>
        </ContenteditableInput>
        {#if $group?.project_type === "unconstrained"}
          <DocumentsInput {group} />
        {/if}
        <GroupAttachments {group} attachments={allAttachments?.none} />
      </div>

      <div class="right-column text-xs space-y-2">
        <div class="flex flex-col items-start sm:items-end">
          <div class="w-full">
            <SelectInput
              labelWidth="1rem"
              options={assignToOptions}
              placeholder="Unassigned"
              value={job.assigned_to_id}
              outerBorder={false}
              showCaret
              on:input={(e) => updateJob({ assigned_to_id: e.detail.value })}>
              <div class="text-black" slot="label"><ProfileIcon /></div>
              <div slot="selected" let:option class="flex items-center gap-1">
                {#if option.initials}
                  <div
                    class="profile-circle relative"
                    style="background-color: {option.color || '#000'}; border-color: {option.color ||
                      '#000'}">
                    {option.initials}
                  </div>
                {/if}
                <div class:text-gray-400={!option.value}>
                  {option.label}
                </div>
              </div>
              <div slot="option" let:option class="flex items-center gap-1 overflow-hidden">
                {#if option.initials}
                  <div
                    class="profile-circle relative"
                    style="background-color: {option.color || '#000'}; border-color: {option.color ||
                      '#000'}">
                    {option.initials}
                  </div>
                {:else}
                  <div class="flex-none h-4 w-4" />
                {/if}
                <div class:text-gray-400={!option.value} class="truncate">
                  {option.label}
                </div>
              </div>
            </SelectInput>
          </div>
        </div>
      </div>

      <div class="center-column space-y-2">
        {#if $group?.project_type === "product"}
          <ProductSummaryTable
            recordId={jobid}
            recordType="job"
            sent={false}
            {group}
            {types}
            {items}
            {org}
            quote={null}
            {job} />
        {:else if $group}
          {#if !items.length}
            <div class="flex gap-2 text-xs px-2 justify-center mb-36">
              <div class="italic py-1">This Shape List contains zero items.</div>
              <button class="text-blue-500" on:click={() => navigate(`/jobs/${jobid}/current`)}
                >Add Items</button>
            </div>
          {:else}
            <SummaryTable sent={false} {group} {types} {items} {org} attachments={allAttachments} />
          {/if}
        {/if}
      </div>
    </div>
  </div>

  <ShareDraftModal org={$org} {job} {group} on:update-job={(e) => updateJob(e.detail)} />
  <ProductSendModal
    {job}
    {group}
    {items}
    {types}
    org={$org}
    on:update-job={(e) => updateJob(e.detail)}
    on:confirm={selfSend} />
  <DownloadModal title="Download Contents" {group} {types} name={job.name} />
  <HomeSettingsModal {group} />
{/if}

<style lang="scss">
  .content-columns {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
    overflow: hidden;

    .left-column,
    .right-column {
      @apply text-xs;
      width: 100%;
    }

    @media (min-width: 768px) {
      .left-column,
      .right-column {
        width: calc(50% - 0.25rem);
      }
    }

    .center-column {
      @apply grow overflow-x-hidden;
    }
  }

  @media (min-width: 1636px) {
    .content-columns {
      flex-wrap: nowrap;

      .left-column,
      .right-column {
        width: 20rem;
      }

      .right-column {
        order: 1;
      }
    }
  }
</style>
