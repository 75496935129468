import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";

function makeMultiCategory(selected) {
  if (selected.length < 2) return null;

  const cats = [...selected];
  const last = cloneDeep(cats.pop());
  last.id = [last.id];

  return cats.reduce((m, category) => {
    Object.entries(category).forEach(([key, value]) => {
      // Data/cache properties need to be merged to identify which properties are mixed
      if (key === "id") {
        m.id.push(value);
      } else if (typeof value === "object" && isEqual(m[key], value)) {
        m[key] = value;
      } else if (m[key] !== value) {
        m[key] = "Mixed";
      }
    });

    return m;
  }, last);
}

export default makeMultiCategory;
